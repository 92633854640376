import { Component, OnInit } from '@angular/core';
import { FlashcardGatewayService } from '../services/flashcard-gateway.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-flashcard-deck',
  templateUrl: './flashcard-deck.component.html',
  styleUrls: ['./flashcard-deck.component.scss']
})
export class FlashcardDeckComponent implements OnInit {
  flashcardset;
  ftext: string;
  btext: string;
  classsml = false;
  classbig = false;
  classxbig = false;
  classxxbig = false;
  titletext: string;
  i = 0;
  x = 0;
  constructor(
    private flashcardgateway: FlashcardGatewayService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const itemid = {itemid: params["itemid"]};
      this.titletext = params["setname"];
      this.flashcardgateway.getItemSet(itemid).then(response => {
        this.flashcardset = response;
        this.ftext = response[0].front;
        this.btext = response[0].back;
        this.x = response.length;
      });
    });
  }

  next(){

    if(this.i === this.x){
      this.ftext = this.flashcardset[this.i].front;
      this.btext = this.flashcardset[this.i].back;
    }
    else if( this.i < this.x){
      this.i++;
      this.ftext = this.flashcardset[this.i].front;
      this.btext = this.flashcardset[this.i].back;
    }
  }
  back(){

    if(this.i === this.x){
      this.i = this.i - 2;
      this.ftext = this.flashcardset[this.i].front;
      this.btext = this.flashcardset[this.i].back;
    }
    else if (this.i > 0){
      this.i--;
      this.ftext = this.flashcardset[this.i].front;
      this.btext = this.flashcardset[this.i].back;
    }
    else{
      this.i = 0;
    }
  }
}
