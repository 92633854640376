import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { Flashcard, FlashcardSetList } from '../types/index';

@Injectable({
  providedIn: 'root'
})
export class FlashcardGatewayService {
  constructor(){}

  async itemSetList(): Promise<any>{
    const apiName = 'er4FlashcardWeb';
    const path = '/listitemset';
    let returnvalue: FlashcardSetList;

    await API.get(apiName, path, '')
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async getItemSet(itemid): Promise<any>{
    const apiName = 'er4FlashcardWeb';
    const path = '/getitemset';
    let returnvalue: FlashcardSetList;

    const myInit = {
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }
}
