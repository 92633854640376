import { Component, OnInit } from '@angular/core';
import { FlashcardGatewayService } from '../services/flashcard-gateway.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
  itemid: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  tiles: Tile[] = [];
  constructor(private flashcardgateway: FlashcardGatewayService) { }

  ngOnInit(): void {
    let col = 1;
    let row = 1;
    let i = 0;
    const colourmap = ['#F9EBEA', '#F2D7D5', '#E6B0AA', '#F5B7B1','#FADBD8'];
    this.flashcardgateway.itemSetList().then(response => {
      response.forEach(element => {
        if (col === 2){
          this.tiles.push({text: element.setname, itemid: element.itemid, cols: col, rows: row, color: colourmap[i]});
          row += 1;
          col = 1;
        }else{
          this.tiles.push({text: element.setname, itemid: element.itemid, cols: col, rows: row, color: colourmap[i]});
          col += 1;
        }
        if(i === (colourmap.length - 1)){
          i = 0;
        }
        else{
          i += 1;
        }

        this.tiles = this.tiles.sort((a, b) => {
          const textA = a.text.toUpperCase();
          const textB = b.text.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      });
    });
  }
}
