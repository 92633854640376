<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span><a class="navitem" href="https://blog.dumbooctopus.net/"><img src="/assets/images/logo.png"></a></span>
    <span><a class="navitem" href="https://blog.dumbooctopus.net/">Blog</a></span>
    <span><a class="navitem" href="https://flashcards.dumbooctopus.net/">Flashcards</a></span>
    <span><a class="navitem" href="https://quiz.dumbooctopus.net/">Quiz</a></span>        
  </mat-toolbar-row>
</mat-toolbar>
<a mat-button href="https://aws.amazon.com/certification/certification-prep/" color="primary" target="_blank">Exam Prep Guide</a>
<a mat-button href="https://www.aws.training/" color="primary" target="_blank">AWS Training and Certification</a>
