import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MaterialModule} from './material-module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import Amplify from 'aws-amplify';
import { FlashcardDeckComponent } from './flashcard-deck/flashcard-deck.component';
import { MarkdownModule } from 'ngx-markdown';

Amplify.configure({
  API: {
    endpoints: [
        {
          name: 'er4FlashcardWeb',
          endpoint: 'https://azucf3wyu2.execute-api.ap-southeast-2.amazonaws.com/prod',
        }
      ]
  }
});
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    FlashcardDeckComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    MarkdownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
