<section class="section">
  <div class="container">
    <div class="columns is-mobile is-centered">
      <div class="column is-full">
        <mat-card class="example-card">
          <mat-card-header><div class="section_title"><mat-card-title>
            {{
            titletext
          }}</mat-card-title></div>
            
          </mat-card-header>
          <mat-card-content>
            <div class="container cardcontainer">
              <div class="columns is-mobile is-centered">
                <div class="column is-half flip-card">
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <markdown [data]="ftext"></markdown>
                    </div>
                    <div class="flip-card-back">
                      <markdown [data]="btext"></markdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="buttons btnclass">
      <button
        mat-button
        #btnnext
        color="primary"
        class="button btnclass is-inverted is-right"
        (click)="back()"
        [disabled]="this.i == 0"
      >
        Back
      </button>
      <button
        mat-button
        #btnnext
        color="primary"
        class="button btnclass is-inverted is-right"
        (click)="next()"
        [disabled]="this.i == this.x - 1"
      >
        Next
      </button>
      <button
        mat-button
        color="primary"
        class="button btnclass is-inverted"
        routerLink="/flashcard"
      >
        End
      </button>
    </div>
  </div>
</section>
