import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FlashcardDeckComponent } from './flashcard-deck/flashcard-deck.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [  {
  path: '',
  component: HomeComponent
},
{
  path: 'flashcard',
  component: HomeComponent
},
{
  path: 'flashcarddeck/:itemid/:setname', component: FlashcardDeckComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
