<section class="section">
  <div class="container">
    <div class="columns is-mobile is-centered">
      <div class="column is-full">
        <h2 class="title">Flashcards</h2>
        <div class="menu_content">
        <mat-grid-list
          cols="3"
          rowHeight="60px"
          [gutterSize]="'10px'"
          color="primary"
        >
          <mat-grid-tile
            *ngFor="let tile of tiles; let i = index"
            [colspan]="1"
            [rowspan]="1"
            [style.background]="tile.color"
            [routerLink]="['/flashcarddeck', tile.itemid, tile.text]"
          >
            {{ tile.text }}
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      </div>
    </div>
  </div>
</section>
